import { useCallback, useContext, useEffect, useState } from "react";
import "./login.css";
import AuthContext from "../../contexts/AuthContext";
import axios from "axios";
import { baseURL } from "../../libs";
import { useRecaptcha } from "../../hooks/useRecaptcha";
import { Loader } from "../Loader";
import { useNavigate } from "react-router-dom";

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { logged, handleSnackbar, setLogged, setApiKey } =
    useContext(AuthContext)!;
  const [loading, setLoading] = useState(false);

  const [formInfo, setFormInfo] = useState<{
    username: string;
    password: string;
  }>({
    username: "",
    password: "",
  });

  const handleLogin = useCallback(
    async (captcha: string | null) => {
      try {
        setLoading(true);
        const data = (
          await axios.post(`${baseURL}/login`, formInfo, {
            headers: {
              captcha,
            },
          })
        ).data as {
          apiKey: string;
        };
        setLogged(true);
        setApiKey(data.apiKey);
        setLoading(false);
      } catch (err: any) {
        handleSnackbar({ variant: "error" }, err.response.data.message);
        setLoading(false);
      }
    },
    [formInfo, handleSnackbar, setApiKey, setLogged]
  );

  const { executeCaptcha } = useRecaptcha(handleLogin);

  useEffect(() => {
    logged && navigate("/media");
  }, [logged, navigate]);

  return (
    <section className="login-wrapper">
      <form className="login-form" onSubmit={executeCaptcha}>
        <h4>Accedi</h4>
        <input
          required
          name="username"
          className="login-input"
          placeholder="Username"
          value={formInfo.username}
          onChange={(e) =>
            setFormInfo({ ...formInfo, [e.target.name]: e.target.value })
          }
        />
        <input
          required
          name="password"
          type="password"
          className="login-input"
          placeholder="Password"
          value={formInfo.password}
          onChange={(e) =>
            setFormInfo({ ...formInfo, [e.target.name]: e.target.value })
          }
        />
        <input type="submit" className="submit" value="Invia" />
      </form>
      {loading && <Loader />}
    </section>
  );
};
