import React from 'react';
import styled from 'styled-components';

const ContactWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  color: #4682b4;
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const ContactInfo = styled.div`
  margin-bottom: 20px;
`;

const ContactItem = styled.p`
  margin-bottom: 10px;
`;

const Contattaci: React.FC = () => {
  return (
    <ContactWrapper>
      <Title>Contattaci</Title>
      <ContactInfo>
        <ContactItem><strong>Indirizzo:</strong> Via Agostino Stellato SNC (Traversa di fronte 'Bar Paradise'), 81054 San Prisco (CE)</ContactItem>
        <ContactItem><strong>Telefono:</strong> 0823 1874120</ContactItem>
        <ContactItem><strong>Cellulare:</strong> 347 8612610</ContactItem>
        <ContactItem><strong>Email:</strong> scuolainfanziawaltdisney@hotmail.it</ContactItem>
      </ContactInfo>
      <ContactInfo>
        <ContactItem><strong>Orari:</strong></ContactItem>
        <ContactItem>Lunedì - Venerdì: 7:30 - 17:00</ContactItem>
        <ContactItem>Sabato: 8:00 - 12:00</ContactItem>
      </ContactInfo>
    </ContactWrapper>
  );
};

export default Contattaci;