import React from "react";
import styled from "styled-components";

const HomeWrapper = styled.div`
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const Welcome = styled.h1`
  color: #4682b4;
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.p`
  color: #ff69b4;
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const PanoramaSection = styled.div`
  margin-bottom: 2rem;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
`;

const GridItem = styled.div`
  border: 1px solid #ddd;
  padding: 1rem;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const PanoramicImage = styled(Image)`
  max-width: 80%;
  margin: 0 auto;
`;

const ServicesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
`;

const ServiceItem = styled.li`
  margin-bottom: 0.5rem;
`;

const ServiceTitle = styled.h2`
  font-size: 2.5rem;
  color: #4682b4;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
`;

const Home: React.FC = () => {
  return (
    <HomeWrapper>
      <Welcome>
        Benvenuti alla Scuola dell'infanzia paritaria "Walt Disney"
      </Welcome>
      <Subtitle>Un mondo di magia, apprendimento e divertimento per i vostri bambini</Subtitle>
      <PanoramaSection>
        <h2>Panoramica Esterna</h2>
        <PanoramicImage src="/1.jpg" alt="Panoramica Esterna" />
      </PanoramaSection>
      <GridContainer>
        <GridItem>
          <h3>Ampio Giardino</h3>
          <Image src="/2.jpg" alt="Giardino" />
        </GridItem>
        <GridItem>
          <h3>Salone</h3>
          <Image src="/3.jpg" alt="Salone" />
        </GridItem>
        <GridItem>
          <h3>Topolino</h3>
          <Image src="/4.jpg" alt="Topolino" />
        </GridItem>
        <GridItem>
          <h3>Sezione Primavera</h3>
          <Image src="/5.jpg" alt="Sezione Primavera" />
        </GridItem>
        <GridItem>
          <h3>Sezione Pesciolini</h3>
          <Image src="/6.jpg" alt="Sezione Pesciolini" />
        </GridItem>
        <GridItem>
          <h3>Sezione Pinocchio</h3>
          <Image src="/7.jpg" alt="Sezione Pinocchio" />
        </GridItem>
        <GridItem>
          <h3>Sezione Walt Disney</h3>
          <Image src="/8.jpg" alt="Sezione Walt Disney" />
        </GridItem>
        <GridItem>
          <h3>Libreria</h3>
          <Image src="/9.jpg" alt="Libreria e Tavolo Interattivo" />
        </GridItem>
        <GridItem>
          <h3>Giardino Gazebo</h3>
          <Image src="/10.jpg" alt="Giardino Gazebo" />
        </GridItem>
      </GridContainer>
      <ServiceTitle>I Nostri Servizi</ServiceTitle>
      <ServicesList>
        <ServiceItem>
          Sezione Primavera autorizzata dal MIUR per bonus INPS
        </ServiceItem>
        <ServiceItem>Sezione Infanzia 3-6 anni</ServiceItem>
        <ServiceItem>Laboratorio d'inglese</ServiceItem>
        <ServiceItem>Trasporto</ServiceItem>
        <ServiceItem>Pranzo e doposcuola</ServiceItem>
        <ServiceItem>Feste di compleanno</ServiceItem>
        <ServiceItem>Campo estivo</ServiceItem>
      </ServicesList>
    </HomeWrapper>
  );
};

export default Home;
