/* eslint-disable no-sequences */
import { IoDocumentText } from "react-icons/io5";
import "./media.css";
import { MdDelete } from "react-icons/md";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { baseURL } from "../../libs";
import AuthContext from "../../contexts/AuthContext";
import { Loader } from "../Loader";
import { useNavigate } from "react-router-dom";

export const Media: React.FC = () => {
  const navigate = useNavigate();
  const { handleSnackbar, apiKey, logged } = useContext(AuthContext)!;
  const [list, setList] = useState<{ label: string; timestamp: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [percentage, setPercentage] = useState(0);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleFileUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        setPercentage(0);
        setLoading(true);
        const uploadedFile = event.target.files?.[0];
        if (uploadedFile) {
          const formData = new FormData();
          formData.append("file", uploadedFile);

          const response = (
            await axios.post(`${baseURL}/file`, formData, {
              headers: {
                Authorization: apiKey,
              },
              onUploadProgress: (progress) =>
                setPercentage(
                  Math.floor((progress.loaded * 100) / progress.total!)
                ),
            })
          ).data as { file: string };

          const { file: e } = response;

          event.target.value = "";

          setList([
            {
              label: e.slice(e.indexOf("_") + 1),
              timestamp: e.slice(0, e.indexOf("_")),
            },
            ...list,
          ]);
        }
        inputRef && (inputRef.current!.value = "");
        setLoading(false);
        handleSnackbar(
          { variant: "success" },
          "Documento caricato correttamente"
        );
      } catch (err: any) {
        handleSnackbar({ variant: "error" }, err.response.data.message);
        inputRef && (inputRef.current!.value = "");
        setLoading(false);
        setPercentage(0);
      }
    },
    [apiKey, handleSnackbar, list]
  );

  const handleGetFiles = useCallback(async () => {
    try {
      setLoading(true);
      const files = (await axios.get(`${baseURL}/file`)).data as string[];
      setList(
        [
          ...files.map((e) => ({
            label: e.slice(e.indexOf("_") + 1),
            timestamp: e.slice(0, e.indexOf("_")),
          })),
        ].reverse()
      );
      setLoading(false);
    } catch (err: any) {
      handleSnackbar(
        { variant: "error" },
        err?.response?.data?.message ?? err.message
      );
      setLoading(false);
    }
  }, [handleSnackbar]);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      await axios.delete(`${baseURL}/file/${selectedFile}`, {
        headers: {
          Authorization: apiKey,
        },
      });
      setList([...list.filter((e) => e.timestamp !== selectedFile)]);
      setLoading(false);
      handleSnackbar(
        { variant: "success" },
        "Documento eliminato correttamente"
      );
    } catch (err: any) {
      handleSnackbar(
        { variant: "error" },
        err?.response?.data?.message ?? err.message
      );
      setLoading(false);
    }
  }, [apiKey, handleSnackbar, list, selectedFile]);

  useEffect(() => {
    handleGetFiles();
    !logged && navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <div className="flex items-center justify-center">
        <input
          type="file"
          onChange={handleFileUpload}
          accept=".pdf,.doc,.docx,.xls,.xlsx"
          multiple={false}
          ref={inputRef}
          style={{ display: "none" }}
        />
        <button
          onClick={() => inputRef && inputRef?.current?.click()}
          className="upload-btn"
        >
          Scegli file
        </button>
      </div>
      <ul className="list">
        {list.map((file) => (
          <li key={file.timestamp}>
            <div className="row">
              <IoDocumentText size={40} style={{ marginRight: 20 }} />
              <p>{file.label}</p>

              <div className="">
                <MdDelete
                  size={20}
                  color="red"
                  style={{ marginLeft: 20, cursor: "pointer" }}
                  onClick={() => (
                    setSelectedFile(file.timestamp), setShowAlert(true)
                  )}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
      {loading && <Loader percentage={percentage} />}

      {showAlert && (
        <div className="alert" onClick={() => setShowAlert(false)}>
          <p>Sicuro di voler eliminare questo file ?</p>
          <div className="flex">
            <button>Annulla</button>
            <button onClick={handleDelete}>Elimina</button>
          </div>
        </div>
      )}
    </section>
  );
};
