import { FormEvent, useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const useRecaptcha = (cb: (token: string | null) => void) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const executeCaptcha = useCallback(
    async (e?: FormEvent<HTMLFormElement>) => {
      e && e.preventDefault();
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha();

      return cb(token);
    },
    [cb, executeRecaptcha]
  );

  return { executeCaptcha };
};
