type Props = {
  percentage?: number;
};

export const Loader: React.FC<Props> = ({ percentage }) => {
  return (
    <div className="loader-box">
      <span className="loader" />
      {!!percentage && <p className="percentage">{percentage}%</p>}
    </div>
  );
};
