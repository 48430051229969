import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { baseURL } from "../libs";
import axios from "axios";
import { Loader } from "./Loader";

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  color: #4682b4;
  margin-bottom: 20px;
`;

const FileList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FileItem = styled.li`
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const AmministrazioneTrasparente: React.FC = () => {
  const [list, setList] = useState<{ label: string; timestamp: string }[]>([]);
  const [loading, setLoading] = useState(false);

  const handleGetFiles = useCallback(async () => {
    try {
      setLoading(true);
      const files = (await axios.get(`${baseURL}/file`)).data as string[];
      setList(
        [
          ...files.map((e) => ({
            label: e.slice(e.indexOf("_") + 1),
            timestamp: e.slice(0, e.indexOf("_")),
          })),
        ].reverse()
      );
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    handleGetFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Title>Amministrazione Trasparente</Title>

      <FileList>
        {list.map((file, index) => (
          <FileItem key={index}>
            <a
              href={`/docs/${file.timestamp}_${file.label}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {file.label}
            </a>
          </FileItem>
        ))}
      </FileList>
      {loading && <Loader />}
    </Container>
  );
};

export default AmministrazioneTrasparente;
