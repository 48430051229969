import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={"6LeT1EMqAAAAAGTqFmZAQwL8qh9zeBqD6Qhl5L9K"}
    >
      <SnackbarProvider autoHideDuration={6000}>
        <App />
      </SnackbarProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
