import React, { useCallback, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  NavLink,
} from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import Home from "./components/Home";
import AmministrazioneTrasparente from "./components/AmministrazioneTrasparente";
import Contattaci from "./components/Contattaci";
import "./App.css";
import AuthContext from "./contexts/AuthContext";
import { Login } from "./components/Login/Login";
import { OptionsObject, useSnackbar } from "notistack";
import { Media } from "./components/Media/Media";

export type SnackbarVariant =
  | OptionsObject<"error" | "success" | "info" | "warning" | "default">
  | undefined;

const App: React.FC = () => {
  const [logged, setLogged] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);
  // ================================================================================ UseSnackbar
  const { enqueueSnackbar } = useSnackbar();
  const handleSnackbar = useCallback(
    (variant: SnackbarVariant, message: string) => {
      return enqueueSnackbar(message, variant);
    },
    [enqueueSnackbar]
  );

  return (
    <AuthContext.Provider
      value={{ logged, setLogged, apiKey, setApiKey, handleSnackbar }}
    >
      <Router>
        <div className="app-wrapper">
          <header className="header">
            <nav className="navbar">
              <Link className="logo" to="/">
                Scuola Infanzia Walt Disney
              </Link>
              <div className="nav-items">
                <NavLink to="/" className="nav-link" end>
                  Home
                </NavLink>
                <NavLink to="/amministrazione-trasparente" className="nav-link">
                  Amministrazione Trasparente
                </NavLink>
                <NavLink to="/contattaci" className="nav-link">
                  Contattaci
                </NavLink>
                {logged && (
                  <NavLink to="/media" className="nav-link">
                    Carica media
                  </NavLink>
                )}
              </div>
            </nav>
          </header>
          <main className="main">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/media" element={<Media />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/amministrazione-trasparente"
                element={<AmministrazioneTrasparente />}
              />
              <Route path="/contattaci" element={<Contattaci />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </main>
          <footer className="footer">
            <p>
              &copy; 2024 Scuola Infanzia Walt Disney. Tutti i diritti
              riservati.
            </p>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/share/eugZE9pHoEzFCwXQ/?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FaFacebook />
              </a>
              <a
                href="https://www.instagram.com/scuolainfanzia_waltdisney?igsh=MXdqZGhqMWNoMzl6NQ=="
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FaInstagram />
              </a>
            </div>
            <p className="privacy">
              Questo sito è protetto da reCAPTCHA e dalla Google{" "}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{" "}
              e{" "}
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Termini di Servizio
              </a>
            </p>
          </footer>
        </div>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
