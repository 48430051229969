import { SnackbarKey } from "notistack";
import { createContext } from "react";
import { SnackbarVariant } from "../App";

interface AuthContext {
  logged: boolean;
  setLogged: React.Dispatch<React.SetStateAction<boolean>>;
  apiKey: string | null;
  setApiKey: React.Dispatch<React.SetStateAction<string | null>>;

  handleSnackbar: (variant: SnackbarVariant, message: string) => SnackbarKey;
}

const context = createContext<AuthContext | null>(null);

export default context;
